import { v4 as uuidV4 } from 'uuid';

export interface IToast {
    id?: string;
    title: string;
    message: string;
    type: string;
    timeout?: number;
}

const toasts = ref<IToast[]>([]);

export function useToasts() {
    const addToast = (options: IToast) => {
        const id = uuidV4();
        if (toasts.value.length > 5)
            toasts.value.pop();

        const { title, message, type, timeout = 3000 } = options;
        toasts.value.unshift({ id, title, message, type, timeout });

        setTimeout(() => {
            toasts.value = toasts.value.filter(toast => toast.id !== id);
        }, timeout);
    };

    const removeToast = (id: string) => {
        toasts.value = toasts.value.filter(toast => toast.id !== id);
    };

    return {
        toasts,
        addToast,
        removeToast
    };
}
